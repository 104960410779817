export const countries = {
  en: [
    {
      value: "AF",
      label: "Afghanistan",
    },
    {
      value: "AX",
      label: "Åland Islands",
    },
    {
      value: "AL",
      label: "Albania",
    },
    {
      value: "DZ",
      label: "Algeria",
    },
    {
      value: "AS",
      label: "American Samoa",
    },
    {
      value: "AD",
      label: "Andorra",
    },
    {
      value: "AO",
      label: "Angola",
    },
    {
      value: "AI",
      label: "Anguilla",
    },
    {
      value: "AQ",
      label: "Antarctica",
    },
    {
      value: "AG",
      label: "Antigua and Barbuda",
    },
    {
      value: "AR",
      label: "Argentina",
    },
    {
      value: "AM",
      label: "Armenia",
    },
    {
      value: "AW",
      label: "Aruba",
    },
    {
      value: "AU",
      label: "Australia",
    },
    {
      value: "AT",
      label: "Austria",
    },
    {
      value: "AZ",
      label: "Azerbaijan",
    },
    {
      value: "BS",
      label: "Bahamas",
    },
    {
      value: "BH",
      label: "Bahrain",
    },
    {
      value: "BD",
      label: "Bangladesh",
    },
    {
      value: "BB",
      label: "Barbados",
    },
    {
      value: "BY",
      label: "Belarus",
    },
    {
      value: "BE",
      label: "Belgium",
    },
    {
      value: "BZ",
      label: "Belize",
    },
    {
      value: "BJ",
      label: "Benin",
    },
    {
      value: "BM",
      label: "Bermuda",
    },
    {
      value: "BT",
      label: "Bhutan",
    },
    {
      value: "BO",
      label: "Bolivia",
    },
    {
      value: "BA",
      label: "Bosnia and Herzegovina",
    },
    {
      value: "BW",
      label: "Botswana",
    },
    {
      value: "BV",
      label: "Bouvet Island",
    },
    {
      value: "BR",
      label: "Brazil",
    },
    {
      value: "IO",
      label: "British Indian Ocean Territory",
    },
    {
      value: "VG",
      label: "British Virgin Islands",
    },
    {
      value: "BN",
      label: "Brunei",
    },
    {
      value: "BG",
      label: "Bulgaria",
    },
    {
      value: "BF",
      label: "Burkina Faso",
    },
    {
      value: "BI",
      label: "Burundi",
    },
    {
      value: "KH",
      label: "Cambodia",
    },
    {
      value: "CM",
      label: "Cameroon",
    },
    {
      value: "CA",
      label: "Canada",
    },
    {
      value: "CV",
      label: "Cape Verde",
    },
    {
      value: "BQ",
      label: "Caribbean Netherlands",
    },
    {
      value: "KY",
      label: "Cayman Islands",
    },
    {
      value: "CF",
      label: "Central African Republic",
    },
    {
      value: "TD",
      label: "Chad",
    },
    {
      value: "CL",
      label: "Chile",
    },
    {
      value: "CN",
      label: "China",
    },
    {
      value: "CX",
      label: "Christmas Island",
    },
    {
      value: "CC",
      label: "Cocos (Keeling) Islands",
    },
    {
      value: "CO",
      label: "Colombia",
    },
    {
      value: "KM",
      label: "Comoros",
    },
    {
      value: "CK",
      label: "Cook Islands",
    },
    {
      value: "CR",
      label: "Costa Rica",
    },
    {
      value: "HR",
      label: "Croatia",
    },
    {
      value: "CU",
      label: "Cuba",
    },
    {
      value: "CW",
      label: "Curaçao",
    },
    {
      value: "CY",
      label: "Cyprus",
    },
    {
      value: "CZ",
      label: "Czechia",
    },
    {
      value: "DK",
      label: "Denmark",
    },
    {
      value: "DJ",
      label: "Djibouti",
    },
    {
      value: "DM",
      label: "Dominica",
    },
    {
      value: "DO",
      label: "Dominican Republic",
    },
    {
      value: "CD",
      label: "DR Congo",
    },
    {
      value: "EC",
      label: "Ecuador",
    },
    {
      value: "EG",
      label: "Egypt",
    },
    {
      value: "SV",
      label: "El Salvador",
    },
    {
      value: "GQ",
      label: "Equatorial Guinea",
    },
    {
      value: "ER",
      label: "Eritrea",
    },
    {
      value: "EE",
      label: "Estonia",
    },
    {
      value: "SZ",
      label: "Eswatini",
    },
    {
      value: "ET",
      label: "Ethiopia",
    },
    {
      value: "FK",
      label: "Falkland Islands",
    },
    {
      value: "FO",
      label: "Faroe Islands",
    },
    {
      value: "FJ",
      label: "Fiji",
    },
    {
      value: "FI",
      label: "Finland",
    },
    {
      value: "FR",
      label: "France",
    },
    {
      value: "GF",
      label: "French Guiana",
    },
    {
      value: "PF",
      label: "French Polynesia",
    },
    {
      value: "TF",
      label: "French Southern and Antarctic Lands",
    },
    {
      value: "GA",
      label: "Gabon",
    },
    {
      value: "GM",
      label: "Gambia",
    },
    {
      value: "GE",
      label: "Georgia",
    },
    {
      value: "DE",
      label: "Germany",
    },
    {
      value: "GH",
      label: "Ghana",
    },
    {
      value: "GI",
      label: "Gibraltar",
    },
    {
      value: "GR",
      label: "Greece",
    },
    {
      value: "GL",
      label: "Greenland",
    },
    {
      value: "GD",
      label: "Grenada",
    },
    {
      value: "GP",
      label: "Guadeloupe",
    },
    {
      value: "GU",
      label: "Guam",
    },
    {
      value: "GT",
      label: "Guatemala",
    },
    {
      value: "GG",
      label: "Guernsey",
    },
    {
      value: "GN",
      label: "Guinea",
    },
    {
      value: "GW",
      label: "Guinea-Bissau",
    },
    {
      value: "GY",
      label: "Guyana",
    },
    {
      value: "HT",
      label: "Haiti",
    },
    {
      value: "HM",
      label: "Heard Island and McDonald Islands",
    },
    {
      value: "HN",
      label: "Honduras",
    },
    {
      value: "HK",
      label: "Hong Kong",
    },
    {
      value: "HU",
      label: "Hungary",
    },
    {
      value: "IS",
      label: "Iceland",
    },
    {
      value: "IN",
      label: "India",
    },
    {
      value: "ID",
      label: "Indonesia",
    },
    {
      value: "IR",
      label: "Iran",
    },
    {
      value: "IQ",
      label: "Iraq",
    },
    {
      value: "IE",
      label: "Ireland",
    },
    {
      value: "IM",
      label: "Isle of Man",
    },
    {
      value: "IL",
      label: "Israel",
    },
    {
      value: "IT",
      label: "Italy",
    },
    {
      value: "CI",
      label: "Ivory Coast",
    },
    {
      value: "JM",
      label: "Jamaica",
    },
    {
      value: "JP",
      label: "Japan",
    },
    {
      value: "JE",
      label: "Jersey",
    },
    {
      value: "JO",
      label: "Jordan",
    },
    {
      value: "KZ",
      label: "Kazakhstan",
    },
    {
      value: "KE",
      label: "Kenya",
    },
    {
      value: "KI",
      label: "Kiribati",
    },
    {
      value: "XK",
      label: "Kosovo",
    },
    {
      value: "KW",
      label: "Kuwait",
    },
    {
      value: "KG",
      label: "Kyrgyzstan",
    },
    {
      value: "LA",
      label: "Laos",
    },
    {
      value: "LV",
      label: "Latvia",
    },
    {
      value: "LB",
      label: "Lebanon",
    },
    {
      value: "LS",
      label: "Lesotho",
    },
    {
      value: "LR",
      label: "Liberia",
    },
    {
      value: "LY",
      label: "Libya",
    },
    {
      value: "LI",
      label: "Liechtenstein",
    },
    {
      value: "LT",
      label: "Lithuania",
    },
    {
      value: "LU",
      label: "Luxembourg",
    },
    {
      value: "MO",
      label: "Macau",
    },
    {
      value: "MG",
      label: "Madagascar",
    },
    {
      value: "MW",
      label: "Malawi",
    },
    {
      value: "MY",
      label: "Malaysia",
    },
    {
      value: "MV",
      label: "Maldives",
    },
    {
      value: "ML",
      label: "Mali",
    },
    {
      value: "MT",
      label: "Malta",
    },
    {
      value: "MH",
      label: "Marshall Islands",
    },
    {
      value: "MQ",
      label: "Martinique",
    },
    {
      value: "MR",
      label: "Mauritania",
    },
    {
      value: "MU",
      label: "Mauritius",
    },
    {
      value: "YT",
      label: "Mayotte",
    },
    {
      value: "MX",
      label: "Mexico",
    },
    {
      value: "FM",
      label: "Micronesia",
    },
    {
      value: "MD",
      label: "Moldova",
    },
    {
      value: "MC",
      label: "Monaco",
    },
    {
      value: "MN",
      label: "Mongolia",
    },
    {
      value: "ME",
      label: "Montenegro",
    },
    {
      value: "MS",
      label: "Montserrat",
    },
    {
      value: "MA",
      label: "Morocco",
    },
    {
      value: "MZ",
      label: "Mozambique",
    },
    {
      value: "MM",
      label: "Myanmar",
    },
    {
      value: "NA",
      label: "Namibia",
    },
    {
      value: "NR",
      label: "Nauru",
    },
    {
      value: "NP",
      label: "Nepal",
    },
    {
      value: "NL",
      label: "Netherlands",
    },
    {
      value: "NC",
      label: "New Caledonia",
    },
    {
      value: "NZ",
      label: "New Zealand",
    },
    {
      value: "NI",
      label: "Nicaragua",
    },
    {
      value: "NE",
      label: "Niger",
    },
    {
      value: "NG",
      label: "Nigeria",
    },
    {
      value: "NU",
      label: "Niue",
    },
    {
      value: "NF",
      label: "Norfolk Island",
    },
    {
      value: "KP",
      label: "North Korea",
    },
    {
      value: "MK",
      label: "North Macedonia",
    },
    {
      value: "MP",
      label: "Northern Mariana Islands",
    },
    {
      value: "NO",
      label: "Norway",
    },
    {
      value: "OM",
      label: "Oman",
    },
    {
      value: "PK",
      label: "Pakistan",
    },
    {
      value: "PW",
      label: "Palau",
    },
    {
      value: "PS",
      label: "Palestine",
    },
    {
      value: "PA",
      label: "Panama",
    },
    {
      value: "PG",
      label: "Papua New Guinea",
    },
    {
      value: "PY",
      label: "Paraguay",
    },
    {
      value: "PE",
      label: "Peru",
    },
    {
      value: "PH",
      label: "Philippines",
    },
    {
      value: "PN",
      label: "Pitcairn Islands",
    },
    {
      value: "PL",
      label: "Poland",
    },
    {
      value: "PT",
      label: "Portugal",
    },
    {
      value: "PR",
      label: "Puerto Rico",
    },
    {
      value: "QA",
      label: "Qatar",
    },
    {
      value: "CG",
      label: "Republic of the Congo",
    },
    {
      value: "RE",
      label: "Réunion",
    },
    {
      value: "RO",
      label: "Romania",
    },
    {
      value: "RU",
      label: "Russia",
    },
    {
      value: "RW",
      label: "Rwanda",
    },
    {
      value: "BL",
      label: "Saint Barthélemy",
    },
    {
      value: "SH",
      label: "Saint Helena, Ascension and Tristan da Cunha",
    },
    {
      value: "KN",
      label: "Saint Kitts and Nevis",
    },
    {
      value: "LC",
      label: "Saint Lucia",
    },
    {
      value: "MF",
      label: "Saint Martin",
    },
    {
      value: "PM",
      label: "Saint Pierre and Miquelon",
    },
    {
      value: "VC",
      label: "Saint Vincent and the Grenadines",
    },
    {
      value: "WS",
      label: "Samoa",
    },
    {
      value: "SM",
      label: "San Marino",
    },
    {
      value: "ST",
      label: "São Tomé and Príncipe",
    },
    {
      value: "SA",
      label: "Saudi Arabia",
    },
    {
      value: "SN",
      label: "Senegal",
    },
    {
      value: "RS",
      label: "Serbia",
    },
    {
      value: "SC",
      label: "Seychelles",
    },
    {
      value: "SL",
      label: "Sierra Leone",
    },
    {
      value: "SG",
      label: "Singapore",
    },
    {
      value: "SX",
      label: "Sint Maarten",
    },
    {
      value: "SK",
      label: "Slovakia",
    },
    {
      value: "SI",
      label: "Slovenia",
    },
    {
      value: "SB",
      label: "Solomon Islands",
    },
    {
      value: "SO",
      label: "Somalia",
    },
    {
      value: "ZA",
      label: "South Africa",
    },
    {
      value: "GS",
      label: "South Georgia",
    },
    {
      value: "KR",
      label: "South Korea",
    },
    {
      value: "SS",
      label: "South Sudan",
    },
    {
      value: "ES",
      label: "Spain",
    },
    {
      value: "LK",
      label: "Sri Lanka",
    },
    {
      value: "SD",
      label: "Sudan",
    },
    {
      value: "SR",
      label: "Suriname",
    },
    {
      value: "SJ",
      label: "Svalbard and Jan Mayen",
    },
    {
      value: "SE",
      label: "Sweden",
    },
    {
      value: "CH",
      label: "Switzerland",
    },
    {
      value: "SY",
      label: "Syria",
    },
    {
      value: "TW",
      label: "Taiwan",
    },
    {
      value: "TJ",
      label: "Tajikistan",
    },
    {
      value: "TZ",
      label: "Tanzania",
    },
    {
      value: "TH",
      label: "Thailand",
    },
    {
      value: "TL",
      label: "Timor-Leste",
    },
    {
      value: "TG",
      label: "Togo",
    },
    {
      value: "TK",
      label: "Tokelau",
    },
    {
      value: "TO",
      label: "Tonga",
    },
    {
      value: "TT",
      label: "Trinidad and Tobago",
    },
    {
      value: "TN",
      label: "Tunisia",
    },
    {
      value: "TR",
      label: "Turkey",
    },
    {
      value: "TM",
      label: "Turkmenistan",
    },
    {
      value: "TC",
      label: "Turks and Caicos Islands",
    },
    {
      value: "TV",
      label: "Tuvalu",
    },
    {
      value: "UG",
      label: "Uganda",
    },
    {
      value: "UA",
      label: "Ukraine",
    },
    {
      value: "AE",
      label: "United Arab Emirates",
    },
    {
      value: "GB",
      label: "United Kingdom",
    },
    {
      value: "US",
      label: "United States",
    },
    {
      value: "UM",
      label: "United States Minor Outlying Islands",
    },
    {
      value: "VI",
      label: "United States Virgin Islands",
    },
    {
      value: "UY",
      label: "Uruguay",
    },
    {
      value: "UZ",
      label: "Uzbekistan",
    },
    {
      value: "VU",
      label: "Vanuatu",
    },
    {
      value: "VA",
      label: "Vatican City",
    },
    {
      value: "VE",
      label: "Venezuela",
    },
    {
      value: "VN",
      label: "Vietnam",
    },
    {
      value: "WF",
      label: "Wallis and Futuna",
    },
    {
      value: "EH",
      label: "Western Sahara",
    },
    {
      value: "YE",
      label: "Yemen",
    },
    {
      value: "ZM",
      label: "Zambia",
    },
    {
      value: "ZW",
      label: "Zimbabwe",
    },
  ],
  ru: [
    {
      value: "AU",
      label: "Австралия",
    },
    {
      value: "AT",
      label: "Австрия",
    },
    {
      value: "AZ",
      label: "Азербайджан",
    },
    {
      value: "AX",
      label: "Аландские острова",
    },
    {
      value: "AL",
      label: "Албания",
    },
    {
      value: "DZ",
      label: "Алжир",
    },
    {
      value: "AS",
      label: "Американское Самоа",
    },
    {
      value: "AI",
      label: "Ангилья",
    },
    {
      value: "AO",
      label: "Ангола",
    },
    {
      value: "AD",
      label: "Андорра",
    },
    {
      value: "AQ",
      label: "Антарктида",
    },
    {
      value: "AG",
      label: "Антигуа и Барбуда",
    },
    {
      value: "AR",
      label: "Аргентина",
    },
    {
      value: "AM",
      label: "Армения",
    },
    {
      value: "AW",
      label: "Аруба",
    },
    {
      value: "AF",
      label: "Афганистан",
    },
    {
      value: "BS",
      label: "Багамские Острова",
    },
    {
      value: "BD",
      label: "Бангладеш",
    },
    {
      value: "BB",
      label: "Барбадос",
    },
    {
      value: "BH",
      label: "Бахрейн",
    },
    {
      value: "BY",
      label: "Беларусь",
    },
    {
      value: "BZ",
      label: "Белиз",
    },
    {
      value: "BE",
      label: "Бельгия",
    },
    {
      value: "BJ",
      label: "Бенин",
    },
    {
      value: "BM",
      label: "Бермудские Острова",
    },
    {
      value: "BG",
      label: "Болгария",
    },
    {
      value: "BO",
      label: "Боливия",
    },
    {
      value: "BA",
      label: "Босния и Герцеговина",
    },
    {
      value: "BW",
      label: "Ботсвана",
    },
    {
      value: "BR",
      label: "Бразилия",
    },
    {
      value: "IO",
      label: "Британская территория в Индийском океане",
    },
    {
      value: "VG",
      label: "Британские Виргинские острова",
    },
    {
      value: "BN",
      label: "Бруней",
    },
    {
      value: "BF",
      label: "Буркина-Фасо",
    },
    {
      value: "BI",
      label: "Бурунди",
    },
    {
      value: "BT",
      label: "Бутан",
    },
    {
      value: "VU",
      label: "Вануату",
    },
    {
      value: "VA",
      label: "Ватикан",
    },
    {
      value: "GB",
      label: "Великобритания",
    },
    {
      value: "HU",
      label: "Венгрия",
    },
    {
      value: "VE",
      label: "Венесуэла",
    },
    {
      value: "VI",
      label: "Виргинские Острова",
    },
    {
      value: "UM",
      label: "Внешние малые острова США",
    },
    {
      value: "TL",
      label: "Восточный Тимор",
    },
    {
      value: "VN",
      label: "Вьетнам",
    },
    {
      value: "GA",
      label: "Габон",
    },
    {
      value: "HT",
      label: "Гаити",
    },
    {
      value: "GY",
      label: "Гайана",
    },
    {
      value: "GM",
      label: "Гамбия",
    },
    {
      value: "GH",
      label: "Гана",
    },
    {
      value: "GP",
      label: "Гваделупа",
    },
    {
      value: "GT",
      label: "Гватемала",
    },
    {
      value: "GN",
      label: "Гвинея",
    },
    {
      value: "GW",
      label: "Гвинея-Бисау",
    },
    {
      value: "DE",
      label: "Германия",
    },
    {
      value: "GG",
      label: "Гернси",
    },
    {
      value: "GI",
      label: "Гибралтар",
    },
    {
      value: "HN",
      label: "Гондурас",
    },
    {
      value: "HK",
      label: "Гонконг",
    },
    {
      value: "GD",
      label: "Гренада",
    },
    {
      value: "GL",
      label: "Гренландия",
    },
    {
      value: "GR",
      label: "Греция",
    },
    {
      value: "GE",
      label: "Грузия",
    },
    {
      value: "GU",
      label: "Гуам",
    },
    {
      value: "DK",
      label: "Дания",
    },
    {
      value: "CD",
      label: "Демократическая Республика Конго",
    },
    {
      value: "JE",
      label: "Джерси",
    },
    {
      value: "DJ",
      label: "Джибути",
    },
    {
      value: "DM",
      label: "Доминика",
    },
    {
      value: "DO",
      label: "Доминиканская Республика",
    },
    {
      value: "EG",
      label: "Египет",
    },
    {
      value: "ZM",
      label: "Замбия",
    },
    {
      value: "EH",
      label: "Западная Сахара",
    },
    {
      value: "ZW",
      label: "Зимбабве",
    },
    {
      value: "IL",
      label: "Израиль",
    },
    {
      value: "IN",
      label: "Индия",
    },
    {
      value: "ID",
      label: "Индонезия",
    },
    {
      value: "JO",
      label: "Иордания",
    },
    {
      value: "IQ",
      label: "Ирак",
    },
    {
      value: "IR",
      label: "Иран",
    },
    {
      value: "IE",
      label: "Ирландия",
    },
    {
      value: "IS",
      label: "Исландия",
    },
    {
      value: "ES",
      label: "Испания",
    },
    {
      value: "IT",
      label: "Италия",
    },
    {
      value: "YE",
      label: "Йемен",
    },
    {
      value: "CV",
      label: "Кабо-Верде",
    },
    {
      value: "KZ",
      label: "Казахстан",
    },
    {
      value: "KY",
      label: "Каймановы острова",
    },
    {
      value: "KH",
      label: "Камбоджа",
    },
    {
      value: "CM",
      label: "Камерун",
    },
    {
      value: "CA",
      label: "Канада",
    },
    {
      value: "BQ",
      label: "Карибские Нидерланды",
    },
    {
      value: "QA",
      label: "Катар",
    },
    {
      value: "KE",
      label: "Кения",
    },
    {
      value: "CY",
      label: "Кипр",
    },
    {
      value: "KG",
      label: "Киргизия",
    },
    {
      value: "KI",
      label: "Кирибати",
    },
    {
      value: "CN",
      label: "Китай",
    },
    {
      value: "CC",
      label: "Кокосовые острова",
    },
    {
      value: "CO",
      label: "Колумбия",
    },
    {
      value: "KM",
      label: "Коморы",
    },
    {
      value: "CR",
      label: "Коста-Рика",
    },
    {
      value: "CI",
      label: "Кот-д’Ивуар",
    },
    {
      value: "CU",
      label: "Куба",
    },
    {
      value: "KW",
      label: "Кувейт",
    },
    {
      value: "CW",
      label: "Кюрасао",
    },
    {
      value: "LA",
      label: "Лаос",
    },
    {
      value: "LV",
      label: "Латвия",
    },
    {
      value: "LS",
      label: "Лесото",
    },
    {
      value: "LR",
      label: "Либерия",
    },
    {
      value: "LB",
      label: "Ливан",
    },
    {
      value: "LY",
      label: "Ливия",
    },
    {
      value: "LT",
      label: "Литва",
    },
    {
      value: "LI",
      label: "Лихтенштейн",
    },
    {
      value: "LU",
      label: "Люксембург",
    },
    {
      value: "MU",
      label: "Маврикий",
    },
    {
      value: "MR",
      label: "Мавритания",
    },
    {
      value: "MG",
      label: "Мадагаскар",
    },
    {
      value: "YT",
      label: "Майотта",
    },
    {
      value: "MO",
      label: "Макао",
    },
    {
      value: "MW",
      label: "Малави",
    },
    {
      value: "MY",
      label: "Малайзия",
    },
    {
      value: "ML",
      label: "Мали",
    },
    {
      value: "MV",
      label: "Мальдивы",
    },
    {
      value: "MT",
      label: "Мальта",
    },
    {
      value: "MA",
      label: "Марокко",
    },
    {
      value: "MQ",
      label: "Мартиника",
    },
    {
      value: "MH",
      label: "Маршалловы Острова",
    },
    {
      value: "MX",
      label: "Мексика",
    },
    {
      value: "MZ",
      label: "Мозамбик",
    },
    {
      value: "MD",
      label: "Молдавия",
    },
    {
      value: "MC",
      label: "Монако",
    },
    {
      value: "MN",
      label: "Монголия",
    },
    {
      value: "MS",
      label: "Монтсеррат",
    },
    {
      value: "MM",
      label: "Мьянма",
    },
    {
      value: "NA",
      label: "Намибия",
    },
    {
      value: "NR",
      label: "Науру",
    },
    {
      value: "NP",
      label: "Непал",
    },
    {
      value: "NE",
      label: "Нигер",
    },
    {
      value: "NG",
      label: "Нигерия",
    },
    {
      value: "NL",
      label: "Нидерланды",
    },
    {
      value: "NI",
      label: "Никарагуа",
    },
    {
      value: "NU",
      label: "Ниуэ",
    },
    {
      value: "NZ",
      label: "Новая Зеландия",
    },
    {
      value: "NC",
      label: "Новая Каледония",
    },
    {
      value: "NO",
      label: "Норвегия",
    },
    {
      value: "NF",
      label: "Норфолк",
    },
    {
      value: "AE",
      label: "Объединённые Арабские Эмираты",
    },
    {
      value: "OM",
      label: "Оман",
    },
    {
      value: "BV",
      label: "Остров Буве",
    },
    {
      value: "IM",
      label: "Остров Мэн",
    },
    {
      value: "CX",
      label: "Остров Рождества",
    },
    {
      value: "HM",
      label: "Остров Херд и острова Макдональд",
    },
    {
      value: "CK",
      label: "Острова Кука",
    },
    {
      value: "PN",
      label: "Острова Питкэрн",
    },
    {
      value: "SH",
      label: "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
    },
    {
      value: "PK",
      label: "Пакистан",
    },
    {
      value: "PW",
      label: "Палау",
    },
    {
      value: "PS",
      label: "Палестина",
    },
    {
      value: "PA",
      label: "Панама",
    },
    {
      value: "PG",
      label: "Папуа — Новая Гвинея",
    },
    {
      value: "PY",
      label: "Парагвай",
    },
    {
      value: "PE",
      label: "Перу",
    },
    {
      value: "PL",
      label: "Польша",
    },
    {
      value: "PT",
      label: "Португалия",
    },
    {
      value: "PR",
      label: "Пуэрто-Рико",
    },
    {
      value: "CG",
      label: "Республика Конго",
    },
    {
      value: "XK",
      label: "Республика Косово",
    },
    {
      value: "RE",
      label: "Реюньон",
    },
    {
      value: "RU",
      label: "Россия",
    },
    {
      value: "RW",
      label: "Руанда",
    },
    {
      value: "RO",
      label: "Румыния",
    },
    {
      value: "SV",
      label: "Сальвадор",
    },
    {
      value: "WS",
      label: "Самоа",
    },
    {
      value: "SM",
      label: "Сан-Марино",
    },
    {
      value: "ST",
      label: "Сан-Томе и Принсипи",
    },
    {
      value: "SA",
      label: "Саудовская Аравия",
    },
    {
      value: "SZ",
      label: "Свазиленд",
    },
    {
      value: "KP",
      label: "Северная Корея",
    },
    {
      value: "MK",
      label: "Северная Македония",
    },
    {
      value: "MP",
      label: "Северные Марианские острова",
    },
    {
      value: "SC",
      label: "Сейшельские Острова",
    },
    {
      value: "BL",
      label: "Сен-Бартелеми",
    },
    {
      value: "MF",
      label: "Сен-Мартен",
    },
    {
      value: "PM",
      label: "Сен-Пьер и Микелон",
    },
    {
      value: "SN",
      label: "Сенегал",
    },
    {
      value: "VC",
      label: "Сент-Винсент и Гренадины",
    },
    {
      value: "KN",
      label: "Сент-Китс и Невис",
    },
    {
      value: "LC",
      label: "Сент-Люсия",
    },
    {
      value: "RS",
      label: "Сербия",
    },
    {
      value: "SG",
      label: "Сингапур",
    },
    {
      value: "SX",
      label: "Синт-Мартен",
    },
    {
      value: "SY",
      label: "Сирия",
    },
    {
      value: "SK",
      label: "Словакия",
    },
    {
      value: "SI",
      label: "Словения",
    },
    {
      value: "US",
      label: "Соединённые Штаты Америки",
    },
    {
      value: "SB",
      label: "Соломоновы Острова",
    },
    {
      value: "SO",
      label: "Сомали",
    },
    {
      value: "SD",
      label: "Судан",
    },
    {
      value: "SR",
      label: "Суринам",
    },
    {
      value: "SL",
      label: "Сьерра-Леоне",
    },
    {
      value: "TJ",
      label: "Таджикистан",
    },
    {
      value: "TH",
      label: "Таиланд",
    },
    {
      value: "TW",
      label: "Тайвань",
    },
    {
      value: "TZ",
      label: "Танзания",
    },
    {
      value: "TC",
      label: "Теркс и Кайкос",
    },
    {
      value: "TG",
      label: "Того",
    },
    {
      value: "TK",
      label: "Токелау",
    },
    {
      value: "TO",
      label: "Тонга",
    },
    {
      value: "TT",
      label: "Тринидад и Тобаго",
    },
    {
      value: "TV",
      label: "Тувалу",
    },
    {
      value: "TN",
      label: "Тунис",
    },
    {
      value: "TM",
      label: "Туркмения",
    },
    {
      value: "TR",
      label: "Турция",
    },
    {
      value: "UG",
      label: "Уганда",
    },
    {
      value: "UZ",
      label: "Узбекистан",
    },
    {
      value: "UA",
      label: "Украина",
    },
    {
      value: "WF",
      label: "Уоллис и Футуна",
    },
    {
      value: "UY",
      label: "Уругвай",
    },
    {
      value: "FO",
      label: "Фарерские острова",
    },
    {
      value: "FM",
      label: "Федеративные Штаты Микронезии",
    },
    {
      value: "FJ",
      label: "Фиджи",
    },
    {
      value: "PH",
      label: "Филиппины",
    },
    {
      value: "FI",
      label: "Финляндия",
    },
    {
      value: "FK",
      label: "Фолклендские острова",
    },
    {
      value: "FR",
      label: "Франция",
    },
    {
      value: "GF",
      label: "Французская Гвиана",
    },
    {
      value: "PF",
      label: "Французская Полинезия",
    },
    {
      value: "TF",
      label: "Французские Южные и Антарктические территории",
    },
    {
      value: "HR",
      label: "Хорватия",
    },
    {
      value: "CF",
      label: "Центральноафриканская Республика",
    },
    {
      value: "TD",
      label: "Чад",
    },
    {
      value: "ME",
      label: "Черногория",
    },
    {
      value: "CZ",
      label: "Чехия",
    },
    {
      value: "CL",
      label: "Чили",
    },
    {
      value: "CH",
      label: "Швейцария",
    },
    {
      value: "SE",
      label: "Швеция",
    },
    {
      value: "SJ",
      label: "Шпицберген и Ян-Майен",
    },
    {
      value: "LK",
      label: "Шри-Ланка",
    },
    {
      value: "EC",
      label: "Эквадор",
    },
    {
      value: "GQ",
      label: "Экваториальная Гвинея",
    },
    {
      value: "ER",
      label: "Эритрея",
    },
    {
      value: "EE",
      label: "Эстония",
    },
    {
      value: "ET",
      label: "Эфиопия",
    },
    {
      value: "GS",
      label: "Южная Георгия и Южные Сандвичевы острова",
    },
    {
      value: "KR",
      label: "Южная Корея",
    },
    {
      value: "ZA",
      label: "Южно-Африканская Республика",
    },
    {
      value: "SS",
      label: "Южный Судан",
    },
    {
      value: "JM",
      label: "Ямайка",
    },
    {
      value: "JP",
      label: "Япония",
    },
  ],
  es: [
    {
      value: "AF",
      label: "Afganistán",
    },
    {
      value: "AX",
      label: "Alandia",
    },
    {
      value: "AL",
      label: "Albania",
    },
    {
      value: "DE",
      label: "Alemania",
    },
    {
      value: "AD",
      label: "Andorra",
    },
    {
      value: "AO",
      label: "Angola",
    },
    {
      value: "AI",
      label: "Anguilla",
    },
    {
      value: "AQ",
      label: "Antártida",
    },
    {
      value: "AG",
      label: "Antigua y Barbuda",
    },
    {
      value: "SA",
      label: "Arabia Saudí",
    },
    {
      value: "DZ",
      label: "Argelia",
    },
    {
      value: "AR",
      label: "Argentina",
    },
    {
      value: "AM",
      label: "Armenia",
    },
    {
      value: "AW",
      label: "Aruba",
    },
    {
      value: "AU",
      label: "Australia",
    },
    {
      value: "AT",
      label: "Austria",
    },
    {
      value: "AZ",
      label: "Azerbaiyán",
    },
    {
      value: "BS",
      label: "Bahamas",
    },
    {
      value: "BH",
      label: "Bahrein",
    },
    {
      value: "BD",
      label: "Bangladesh",
    },
    {
      value: "BB",
      label: "Barbados",
    },
    {
      value: "BE",
      label: "Bélgica",
    },
    {
      value: "BZ",
      label: "Belice",
    },
    {
      value: "BJ",
      label: "Benín",
    },
    {
      value: "BM",
      label: "Bermudas",
    },
    {
      value: "BY",
      label: "Bielorrusia",
    },
    {
      value: "BO",
      label: "Bolivia",
    },
    {
      value: "BA",
      label: "Bosnia y Herzegovina",
    },
    {
      value: "BW",
      label: "Botswana",
    },
    {
      value: "BR",
      label: "Brasil",
    },
    {
      value: "BN",
      label: "Brunei",
    },
    {
      value: "BG",
      label: "Bulgaria",
    },
    {
      value: "BF",
      label: "Burkina Faso",
    },
    {
      value: "BI",
      label: "Burundi",
    },
    {
      value: "BT",
      label: "Bután",
    },
    {
      value: "CV",
      label: "Cabo Verde",
    },
    {
      value: "KH",
      label: "Camboya",
    },
    {
      value: "CM",
      label: "Camerún",
    },
    {
      value: "CA",
      label: "Canadá",
    },
    {
      value: "BQ",
      label: "Caribe Neerlandés",
    },
    {
      value: "QA",
      label: "Catar",
    },
    {
      value: "TD",
      label: "Chad",
    },
    {
      value: "CZ",
      label: "Chequia",
    },
    {
      value: "CL",
      label: "Chile",
    },
    {
      value: "CN",
      label: "China",
    },
    {
      value: "CY",
      label: "Chipre",
    },
    {
      value: "VA",
      label: "Ciudad del Vaticano",
    },
    {
      value: "CO",
      label: "Colombia",
    },
    {
      value: "KM",
      label: "Comoras",
    },
    {
      value: "CG",
      label: "Congo",
    },
    {
      value: "CD",
      label: "Congo (Rep. Dem.)",
    },
    {
      value: "KP",
      label: "Corea del Norte",
    },
    {
      value: "KR",
      label: "Corea del Sur",
    },
    {
      value: "CI",
      label: "Costa de Marfil",
    },
    {
      value: "CR",
      label: "Costa Rica",
    },
    {
      value: "HR",
      label: "Croacia",
    },
    {
      value: "CU",
      label: "Cuba",
    },
    {
      value: "CW",
      label: "Curazao",
    },
    {
      value: "DK",
      label: "Dinamarca",
    },
    {
      value: "DJ",
      label: "Djibouti",
    },
    {
      value: "DM",
      label: "Dominica",
    },
    {
      value: "EC",
      label: "Ecuador",
    },
    {
      value: "EG",
      label: "Egipto",
    },
    {
      value: "SV",
      label: "El Salvador",
    },
    {
      value: "AE",
      label: "Emiratos Árabes Unidos",
    },
    {
      value: "ER",
      label: "Eritrea",
    },
    {
      value: "SI",
      label: "Eslovenia",
    },
    {
      value: "ES",
      label: "España",
    },
    {
      value: "US",
      label: "Estados Unidos",
    },
    {
      value: "EE",
      label: "Estonia",
    },
    {
      value: "ET",
      label: "Etiopía",
    },
    {
      value: "PH",
      label: "Filipinas",
    },
    {
      value: "FI",
      label: "Finlandia",
    },
    {
      value: "FJ",
      label: "Fiyi",
    },
    {
      value: "FR",
      label: "Francia",
    },
    {
      value: "GA",
      label: "Gabón",
    },
    {
      value: "GM",
      label: "Gambia",
    },
    {
      value: "GE",
      label: "Georgia",
    },
    {
      value: "GH",
      label: "Ghana",
    },
    {
      value: "GI",
      label: "Gibraltar",
    },
    {
      value: "GR",
      label: "Grecia",
    },
    {
      value: "GD",
      label: "Grenada",
    },
    {
      value: "GL",
      label: "Groenlandia",
    },
    {
      value: "GP",
      label: "Guadalupe",
    },
    {
      value: "GU",
      label: "Guam",
    },
    {
      value: "GT",
      label: "Guatemala",
    },
    {
      value: "GF",
      label: "Guayana Francesa",
    },
    {
      value: "GG",
      label: "Guernsey",
    },
    {
      value: "GN",
      label: "Guinea",
    },
    {
      value: "GQ",
      label: "Guinea Ecuatorial",
    },
    {
      value: "GW",
      label: "Guinea-Bisáu",
    },
    {
      value: "GY",
      label: "Guyana",
    },
    {
      value: "HT",
      label: "Haití",
    },
    {
      value: "HN",
      label: "Honduras",
    },
    {
      value: "HK",
      label: "Hong Kong",
    },
    {
      value: "HU",
      label: "Hungría",
    },
    {
      value: "IN",
      label: "India",
    },
    {
      value: "ID",
      label: "Indonesia",
    },
    {
      value: "IQ",
      label: "Irak",
    },
    {
      value: "IR",
      label: "Iran",
    },
    {
      value: "IE",
      label: "Irlanda",
    },
    {
      value: "BV",
      label: "Isla Bouvet",
    },
    {
      value: "IM",
      label: "Isla de Man",
    },
    {
      value: "CX",
      label: "Isla de Navidad",
    },
    {
      value: "NF",
      label: "Isla de Norfolk",
    },
    {
      value: "IS",
      label: "Islandia",
    },
    {
      value: "KY",
      label: "Islas Caimán",
    },
    {
      value: "CC",
      label: "Islas Cocos o Islas Keeling",
    },
    {
      value: "CK",
      label: "Islas Cook",
    },
    {
      value: "FO",
      label: "Islas Faroe",
    },
    {
      value: "GS",
      label: "Islas Georgias del Sur y Sandwich del Sur",
    },
    {
      value: "HM",
      label: "Islas Heard y McDonald",
    },
    {
      value: "FK",
      label: "Islas Malvinas",
    },
    {
      value: "MP",
      label: "Islas Marianas del Norte",
    },
    {
      value: "MH",
      label: "Islas Marshall",
    },
    {
      value: "PN",
      label: "Islas Pitcairn",
    },
    {
      value: "SB",
      label: "Islas Salomón",
    },
    {
      value: "SJ",
      label: "Islas Svalbard y Jan Mayen",
    },
    {
      value: "TK",
      label: "Islas Tokelau",
    },
    {
      value: "TC",
      label: "Islas Turks y Caicos",
    },
    {
      value: "UM",
      label: "Islas Ultramarinas Menores de Estados Unidos",
    },
    {
      value: "VI",
      label: "Islas Vírgenes de los Estados Unidos",
    },
    {
      value: "VG",
      label: "Islas Vírgenes del Reino Unido",
    },
    {
      value: "IL",
      label: "Israel",
    },
    {
      value: "IT",
      label: "Italia",
    },
    {
      value: "JM",
      label: "Jamaica",
    },
    {
      value: "JP",
      label: "Japón",
    },
    {
      value: "JE",
      label: "Jersey",
    },
    {
      value: "JO",
      label: "Jordania",
    },
    {
      value: "KZ",
      label: "Kazajistán",
    },
    {
      value: "KE",
      label: "Kenia",
    },
    {
      value: "KG",
      label: "Kirguizistán",
    },
    {
      value: "KI",
      label: "Kiribati",
    },
    {
      value: "XK",
      label: "Kosovo",
    },
    {
      value: "KW",
      label: "Kuwait",
    },
    {
      value: "LA",
      label: "Laos",
    },
    {
      value: "LS",
      label: "Lesotho",
    },
    {
      value: "LV",
      label: "Letonia",
    },
    {
      value: "LB",
      label: "Líbano",
    },
    {
      value: "LR",
      label: "Liberia",
    },
    {
      value: "LY",
      label: "Libia",
    },
    {
      value: "LI",
      label: "Liechtenstein",
    },
    {
      value: "LT",
      label: "Lituania",
    },
    {
      value: "LU",
      label: "Luxemburgo",
    },
    {
      value: "MO",
      label: "Macao",
    },
    {
      value: "MK",
      label: "Macedonia del Norte",
    },
    {
      value: "MG",
      label: "Madagascar",
    },
    {
      value: "MY",
      label: "Malasia",
    },
    {
      value: "MW",
      label: "Malawi",
    },
    {
      value: "MV",
      label: "Maldivas",
    },
    {
      value: "ML",
      label: "Mali",
    },
    {
      value: "MT",
      label: "Malta",
    },
    {
      value: "MA",
      label: "Marruecos",
    },
    {
      value: "MQ",
      label: "Martinica",
    },
    {
      value: "MU",
      label: "Mauricio",
    },
    {
      value: "MR",
      label: "Mauritania",
    },
    {
      value: "YT",
      label: "Mayotte",
    },
    {
      value: "MX",
      label: "México",
    },
    {
      value: "FM",
      label: "Micronesia",
    },
    {
      value: "MD",
      label: "Moldavia",
    },
    {
      value: "MC",
      label: "Mónaco",
    },
    {
      value: "MN",
      label: "Mongolia",
    },
    {
      value: "ME",
      label: "Montenegro",
    },
    {
      value: "MS",
      label: "Montserrat",
    },
    {
      value: "MZ",
      label: "Mozambique",
    },
    {
      value: "MM",
      label: "Myanmar",
    },
    {
      value: "NA",
      label: "Namibia",
    },
    {
      value: "NR",
      label: "Nauru",
    },
    {
      value: "NP",
      label: "Nepal",
    },
    {
      value: "NI",
      label: "Nicaragua",
    },
    {
      value: "NE",
      label: "Níger",
    },
    {
      value: "NG",
      label: "Nigeria",
    },
    {
      value: "NU",
      label: "Niue",
    },
    {
      value: "NO",
      label: "Noruega",
    },
    {
      value: "NC",
      label: "Nueva Caledonia",
    },
    {
      value: "NZ",
      label: "Nueva Zelanda",
    },
    {
      value: "OM",
      label: "Omán",
    },
    {
      value: "NL",
      label: "Países Bajos",
    },
    {
      value: "PK",
      label: "Pakistán",
    },
    {
      value: "PW",
      label: "Palau",
    },
    {
      value: "PS",
      label: "Palestina",
    },
    {
      value: "PA",
      label: "Panamá",
    },
    {
      value: "PG",
      label: "Papúa Nueva Guinea",
    },
    {
      value: "PY",
      label: "Paraguay",
    },
    {
      value: "PE",
      label: "Perú",
    },
    {
      value: "PF",
      label: "Polinesia Francesa",
    },
    {
      value: "PL",
      label: "Polonia",
    },
    {
      value: "PT",
      label: "Portugal",
    },
    {
      value: "PR",
      label: "Puerto Rico",
    },
    {
      value: "GB",
      label: "Reino Unido",
    },
    {
      value: "CF",
      label: "República Centroafricana",
    },
    {
      value: "ZA",
      label: "República de Sudáfrica",
    },
    {
      value: "DO",
      label: "República Dominicana",
    },
    {
      value: "SK",
      label: "República Eslovaca",
    },
    {
      value: "RE",
      label: "Reunión",
    },
    {
      value: "RW",
      label: "Ruanda",
    },
    {
      value: "RO",
      label: "Rumania",
    },
    {
      value: "RU",
      label: "Rusia",
    },
    {
      value: "EH",
      label: "Sahara Occidental",
    },
    {
      value: "MF",
      label: "Saint Martin",
    },
    {
      value: "WS",
      label: "Samoa",
    },
    {
      value: "AS",
      label: "Samoa Americana",
    },
    {
      value: "BL",
      label: "San Bartolomé",
    },
    {
      value: "KN",
      label: "San Cristóbal y Nieves",
    },
    {
      value: "SM",
      label: "San Marino",
    },
    {
      value: "PM",
      label: "San Pedro y Miquelón",
    },
    {
      value: "VC",
      label: "San Vicente y Granadinas",
    },
    {
      value: "SH",
      label: "Santa Elena, Ascensión y Tristán de Acuña",
    },
    {
      value: "LC",
      label: "Santa Lucía",
    },
    {
      value: "ST",
      label: "Santo Tomé y Príncipe",
    },
    {
      value: "SN",
      label: "Senegal",
    },
    {
      value: "RS",
      label: "Serbia",
    },
    {
      value: "SC",
      label: "Seychelles",
    },
    {
      value: "SL",
      label: "Sierra Leone",
    },
    {
      value: "SG",
      label: "Singapur",
    },
    {
      value: "SX",
      label: "Sint Maarten",
    },
    {
      value: "SY",
      label: "Siria",
    },
    {
      value: "SO",
      label: "Somalia",
    },
    {
      value: "LK",
      label: "Sri Lanka",
    },
    {
      value: "SZ",
      label: "Suazilandia",
    },
    {
      value: "SD",
      label: "Sudán",
    },
    {
      value: "SS",
      label: "Sudán del Sur",
    },
    {
      value: "SE",
      label: "Suecia",
    },
    {
      value: "CH",
      label: "Suiza",
    },
    {
      value: "SR",
      label: "Surinam",
    },
    {
      value: "TH",
      label: "Tailandia",
    },
    {
      value: "TW",
      label: "Taiwán",
    },
    {
      value: "TZ",
      label: "Tanzania",
    },
    {
      value: "TJ",
      label: "Tayikistán",
    },
    {
      value: "IO",
      label: "Territorio Británico del Océano Índico",
    },
    {
      value: "TF",
      label: "Tierras Australes y Antárticas Francesas",
    },
    {
      value: "TL",
      label: "Timor Oriental",
    },
    {
      value: "TG",
      label: "Togo",
    },
    {
      value: "TO",
      label: "Tonga",
    },
    {
      value: "TT",
      label: "Trinidad y Tobago",
    },
    {
      value: "TN",
      label: "Túnez",
    },
    {
      value: "TM",
      label: "Turkmenistán",
    },
    {
      value: "TR",
      label: "Turquía",
    },
    {
      value: "TV",
      label: "Tuvalu",
    },
    {
      value: "UA",
      label: "Ucrania",
    },
    {
      value: "UG",
      label: "Uganda",
    },
    {
      value: "UY",
      label: "Uruguay",
    },
    {
      value: "UZ",
      label: "Uzbekistán",
    },
    {
      value: "VU",
      label: "Vanuatu",
    },
    {
      value: "VE",
      label: "Venezuela",
    },
    {
      value: "VN",
      label: "Vietnam",
    },
    {
      value: "WF",
      label: "Wallis y Futuna",
    },
    {
      value: "YE",
      label: "Yemen",
    },
    {
      value: "ZM",
      label: "Zambia",
    },
    {
      value: "ZW",
      label: "Zimbabue",
    },
  ],
  tr: [
    { value: "TR", label: "Türkiye" },
    { value: "VI", label: "ABD Virgin Adaları" },
    { value: "AF", label: "Afganistan" },
    { value: "AX", label: "Aland Adaları" },
    { value: "DE", label: "Almanya" },
    { value: "US", label: "Amerika Birleşik Devletleri" },
    { value: "UM", label: "Amerika Birleşik Devletleri Küçük Dış Adaları" },
    { value: "AS", label: "Amerikan Samoası" },
    { value: "AD", label: "Andora" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarktika" },
    { value: "AG", label: "Antigua ve Barbuda" },
    { value: "AR", label: "Arjantin" },
    { value: "AL", label: "Arnavutluk" },
    { value: "AW", label: "Aruba" },
    { value: "QU", label: "Avrupa Birliği" },
    { value: "AU", label: "Avustralya" },
    { value: "AT", label: "Avusturya" },
    { value: "AZ", label: "Azerbaycan" },
    { value: "BS", label: "Bahamalar" },
    { value: "BH", label: "Bahreyn" },
    { value: "BD", label: "Bangladeş" },
    { value: "BB", label: "Barbados" },
    { value: "EH", label: "Batı Sahara" },
    { value: "BZ", label: "Belize" },
    { value: "BE", label: "Belçika" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BY", label: "Beyaz Rusya" },
    { value: "BT", label: "Bhutan" },
    { value: "ZZ", label: "Bilinmeyen veya Geçersiz Bölge" },
    { value: "AE", label: "Birleşik Arap Emirlikleri" },
    { value: "GB", label: "Birleşik Krallık" },
    { value: "BO", label: "Bolivya" },
    { value: "BA", label: "Bosna Hersek" },
    { value: "BW", label: "Botsvana" },
    { value: "BV", label: "Bouvet Adası" },
    { value: "BR", label: "Brezilya" },
    { value: "BN", label: "Brunei" },
    { value: "BG", label: "Bulgaristan" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "CV", label: "Cape Verde" },
    { value: "GI", label: "Cebelitarık" },
    { value: "DZ", label: "Cezayir" },
    { value: "CX", label: "Christmas Adası" },
    { value: "DJ", label: "Cibuti" },
    { value: "CC", label: "Cocos Adaları" },
    { value: "CK", label: "Cook Adaları" },
    { value: "TD", label: "Çad" },
    { value: "CZ", label: "Çek Cumhuriyeti" },
    { value: "CN", label: "Çin" },
    { value: "DK", label: "Danimarka" },
    { value: "DM", label: "Dominik" },
    { value: "DO", label: "Dominik Cumhuriyeti" },
    { value: "TL", label: "Doğu Timor" },
    { value: "EC", label: "Ekvator" },
    { value: "GQ", label: "Ekvator Ginesi" },
    { value: "SV", label: "El Salvador" },
    { value: "ID", label: "Endonezya" },
    { value: "ER", label: "Eritre" },
    { value: "AM", label: "Ermenistan" },
    { value: "EE", label: "Estonya" },
    { value: "ET", label: "Etiyopya" },
    { value: "FK", label: "Falkland Adaları (Malvinalar)" },
    { value: "FO", label: "Faroe Adaları" },
    { value: "MA", label: "Fas" },
    { value: "FJ", label: "Fiji" },
    { value: "CI", label: "Fildişi Sahilleri" },
    { value: "PH", label: "Filipinler" },
    { value: "PS", label: "Filistin Bölgesi" },
    { value: "FI", label: "Finlandiya" },
    { value: "FR", label: "Fransa" },
    { value: "GF", label: "Fransız Guyanası" },
    { value: "TF", label: "Fransız Güney Bölgeleri" },
    { value: "PF", label: "Fransız Polinezyası" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GH", label: "Gana" },
    { value: "GN", label: "Gine" },
    { value: "GW", label: "Gine-Bissau" },
    { value: "GD", label: "Granada" },
    { value: "GL", label: "Grönland" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GY", label: "Guyana" },
    { value: "ZA", label: "Güney Afrika" },
    { value: "GS", label: "Güney Georgia ve Güney Sandwich Adaları" },
    { value: "KR", label: "Güney Kore" },
    { value: "CY", label: "Güney Kıbrıs Rum Kesimi" },
    { value: "GE", label: "Gürcistan" },
    { value: "HT", label: "Haiti" },
    { value: "HM", label: "Heard Adası ve McDonald Adaları" },
    { value: "IN", label: "Hindistan" },
    { value: "IO", label: "Hint Okyanusu İngiliz Bölgesi" },
    { value: "NL", label: "Hollanda" },
    { value: "AN", label: "Hollanda Antilleri" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong SAR - Çin" },
    { value: "HR", label: "Hırvatistan" },
    { value: "IQ", label: "Irak" },
    { value: "VG", label: "İngiliz Virgin Adaları" },
    { value: "IR", label: "İran" },
    { value: "IE", label: "İrlanda" },
    { value: "ES", label: "İspanya" },
    { value: "IL", label: "İsrail" },
    { value: "SE", label: "İsveç" },
    { value: "CH", label: "İsviçre" },
    { value: "IT", label: "İtalya" },
    { value: "IS", label: "İzlanda" },
    { value: "JM", label: "Jamaika" },
    { value: "JP", label: "Japonya" },
    { value: "JE", label: "Jersey" },
    { value: "KH", label: "Kamboçya" },
    { value: "CM", label: "Kamerun" },
    { value: "CA", label: "Kanada" },
    { value: "ME", label: "Karadağ" },
    { value: "QA", label: "Katar" },
    { value: "KY", label: "Kayman Adaları" },
    { value: "KZ", label: "Kazakistan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "CO", label: "Kolombiya" },
    { value: "KM", label: "Komorlar" },
    { value: "CG", label: "Kongo" },
    { value: "CD", label: "Kongo Demokratik Cumhuriyeti" },
    { value: "CR", label: "Kosta Rika" },
    { value: "KW", label: "Kuveyt" },
    { value: "KP", label: "Kuzey Kore" },
    { value: "MP", label: "Kuzey Mariana Adaları" },
    { value: "CU", label: "Küba" },
    { value: "KG", label: "Kırgızistan" },
    { value: "LA", label: "Laos" },
    { value: "LS", label: "Lesotho" },
    { value: "LV", label: "Letonya" },
    { value: "LR", label: "Liberya" },
    { value: "LY", label: "Libya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Litvanya" },
    { value: "LB", label: "Lübnan" },
    { value: "LU", label: "Lüksemburg" },
    { value: "HU", label: "Macaristan" },
    { value: "MG", label: "Madagaskar" },
    { value: "MO", label: "Makao S.A.R. Çin" },
    { value: "MK", label: "Makedonya" },
    { value: "MW", label: "Malavi" },
    { value: "MV", label: "Maldivler" },
    { value: "MY", label: "Malezya" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "IM", label: "Man Adası" },
    { value: "MH", label: "Marshall Adaları" },
    { value: "MQ", label: "Martinik" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Meksika" },
    { value: "FM", label: "Mikronezya Federal Eyaletleri" },
    { value: "MD", label: "Moldovya Cumhuriyeti" },
    { value: "MC", label: "Monako" },
    { value: "MS", label: "Montserrat" },
    { value: "MR", label: "Moritanya" },
    { value: "MZ", label: "Mozambik" },
    { value: "MN", label: "Moğolistan" },
    { value: "MM", label: "Myanmar" },
    { value: "EG", label: "Mısır" },
    { value: "NA", label: "Namibya" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NE", label: "Nijer" },
    { value: "NG", label: "Nijerya" },
    { value: "NI", label: "Nikaragua" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Adası" },
    { value: "NO", label: "Norveç" },
    { value: "CF", label: "Orta Afrika Cumhuriyeti" },
    { value: "UZ", label: "Özbekistan" },
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua Yeni Gine" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Polonya" },
    { value: "PT", label: "Portekiz" },
    { value: "PR", label: "Porto Riko" },
    { value: "RE", label: "Reunion" },
    { value: "RO", label: "Romanya" },
    { value: "RW", label: "Ruanda" },
    { value: "RU", label: "Rusya Federasyonu" },
    { value: "SH", label: "Saint Helena" },
    { value: "KN", label: "Saint Kitts ve Nevis" },
    { value: "LC", label: "Saint Lucia" },
    { value: "PM", label: "Saint Pierre ve Miquelon" },
    { value: "VC", label: "Saint Vincent ve Grenadinler" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome ve Principe" },
    { value: "SN", label: "Senegal" },
    { value: "SC", label: "Seyşeller" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapur" },
    { value: "SK", label: "Slovakya" },
    { value: "SI", label: "Slovenya" },
    { value: "SB", label: "Solomon Adaları" },
    { value: "SO", label: "Somali" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Surinam" },
    { value: "SY", label: "Suriye" },
    { value: "SA", label: "Suudi Arabistan" },
    { value: "SJ", label: "Svalbard ve Jan Mayen" },
    { value: "SZ", label: "Svaziland" },
    { value: "RS", label: "Sırbistan" },
    { value: "CS", label: "Sırbistan-Karadağ" },
    { value: "CL", label: "Şili" },
    { value: "TJ", label: "Tacikistan" },
    { value: "TZ", label: "Tanzanya" },
    { value: "TH", label: "Tayland" },
    { value: "TW", label: "Tayvan" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad ve Tobago" },
    { value: "TN", label: "Tunus" },
    { value: "TC", label: "Turks ve Caicos Adaları" },
    { value: "TV", label: "Tuvalu" },
    { value: "TM", label: "Türkmenistan" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukrayna" },
    { value: "OM", label: "Umman" },
    { value: "UY", label: "Uruguay" },
    { value: "QO", label: "Uzak Okyanusya" },
    { value: "JO", label: "Ürdün" },
    { value: "VU", label: "Vanuatu" },
    { value: "VA", label: "Vatikan" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Vietnam" },
    { value: "WF", label: "Wallis ve Futuna" },
    { value: "YE", label: "Yemen" },
    { value: "NC", label: "Yeni Kaledonya" },
    { value: "NZ", label: "Yeni Zelanda" },
    { value: "GR", label: "Yunanistan" },
    { value: "ZM", label: "Zambiya" },
    { value: "ZW", label: "Zimbabve" },
  ],
};

export const plantList = {
  tr: [
    { value: "tomato", label: "Domates" },
    { value: "potato", label: "Patates" },
    { value: "eggplant", label: "Patlıcan" },
    { value: "cabbage", label: "Lahana" },
    { value: "cucumber", label: "Hıyar" },
    { value: "carrot", label: "Havuç" },
    { value: "pepper", label: "Biber" },
    { value: "lettuce", label: "Marul" },
    { value: "parsley", label: "Maydonoz" },
    { value: "cauliflower", label: "Karnıbahar" },
    { value: "broccoli", label: "Brokoli" },
    { value: "spinach", label: "Ispanak" },
    { value: "green-beans", label: "Taze Fasulye" },
    { value: "kidney-bean", label: "Barbunya" },
    { value: "peas", label: "Bezelye" },
    { value: "canola", label: "Kanola" },
    { value: "onion", label: "Soğan" },
    { value: "garlic", label: "Sarımsak" },
    { value: "melon", label: "Kavun" },
    { value: "watermelon", label: "Karpuz" },
    { value: "cherry", label: "Kiraz" },
    { value: "sour-cherry", label: "Vişne" },
    { value: "strawberry", label: "Çilek" },
    { value: "kiwi", label: "Kivi" },
    { value: "zucchini", label: "Kabak" },
    { value: "pumpkin", label: "Balkabağı" },
    { value: "pineapple", label: "Ananas" },
    { value: "orange", label: "Portakal" },
    { value: "mandarin", label: "Mandalina" },
    { value: "grapefruit", label: "Greyfurt" },
    { value: "bitter-orange", label: "Turunç" },
    { value: "fig", label: "İncir" },
    { value: "pomegranate", label: "Nar" },
    { value: "quince", label: "Ayva" },
    { value: "apple", label: "Elma" },
    { value: "pear", label: "Armut" },
    { value: "peach", label: "Şeftali" },
    { value: "grape", label: "Üzüm" },
    { value: "apricot", label: "Kayısı" },
    { value: "plum", label: "Erik" },
    { value: "lemon", label: "Limon" },
    { value: "lime", label: "Lime" },
    { value: "peanut", label: "Fındık" },
    { value: "walnut", label: "Ceviz" },
    { value: "wheat", label: "Buğday" },
    { value: "barley", label: "Arpa" },
    { value: "oat", label: "Yulaf" },
    { value: "paddy", label: "Çeltik" },
    { value: "corn", label: "Mısır" },
    { value: "millet", label: "Darı" },
    { value: "rye", label: "Çavdar" },
  ],
  en: [
    { value: "tomato", label: "Tomato" },
    { value: "potato", label: "Potato" },
    { value: "eggplant", label: "Eggplant" },
    { value: "cabbage", label: "Cabbage" },
    { value: "cucumber", label: "Cucumber" },
    { value: "carrot", label: "Carrot" },
    { value: "pepper", label: "Pepper" },
    { value: "lettuce", label: "Lettuce" },
    { value: "parsley", label: "Parsley" },
    { value: "cauliflower", label: "Cauliflower" },
    { value: "broccoli", label: "Broccoli" },
    { value: "spinach", label: "Spinach" },
    { value: "green-beans", label: "Green beans" },
    { value: "kidney-bean", label: "Kidney bean" },
    { value: "peas", label: "Peas" },
    { value: "canola", label: "Canola" },
    { value: "onion", label: "Onion" },
    { value: "garlic", label: "Garlic" },
    { value: "melon", label: "Melon" },
    { value: "watermelon", label: "Watermelon" },
    { value: "cherry", label: "Cherry" },
    { value: "sour-cherry", label: "Sour cherry" },
    { value: "strawberry", label: "Strawberry" },
    { value: "kiwi", label: "Kiwi" },
    { value: "zucchini", label: "Zucchini" },
    { value: "pumpkin", label: "Pumpkin" },
    { value: "pineapple", label: "Pineapple" },
    { value: "orange", label: "Orange" },
    { value: "mandarin", label: "Mandarin" },
    { value: "grapefruit", label: "Grapefruit" },
    { value: "bitter-orange", label: "Bitter orange" },
    { value: "fig", label: "Fig" },
    { value: "pomegranate", label: "Pomegranate" },
    { value: "quince", label: "Quince" },
    { value: "apple", label: "Apple" },
    { value: "pear", label: "Pear" },
    { value: "peach", label: "Peach" },
    { value: "grape", label: "Grape" },
    { value: "apricot", label: "Apricot" },
    { value: "plum", label: "Plum" },
    { value: "lemon", label: "Lemon" },
    { value: "lime", label: "Lime" },
    { value: "peanut", label: "Peanut" },
    { value: "walnut", label: "Walnut" },
    { value: "wheat", label: "Wheat" },
    { value: "barley", label: "Barley" },
    { value: "oat", label: "Oat" },
    { value: "paddy", label: "Paddy" },
    { value: "corn", label: "Corn" },
    { value: "millet", label: "Millet" },
    { value: "rye", label: "Rye" },
  ],
  es: [
    {
      value: "tomato",
      label: "Tomate",
    },
    {
      value: "potato",
      label: "Patata",
    },
    {
      value: "eggplant",
      label: "Berenjena",
    },
    {
      value: "cabbage",
      label: "Repollo",
    },
    {
      value: "cucumber",
      label: "Pepino",
    },
    {
      value: "carrot",
      label: "Zanahoria",
    },
    {
      value: "pepper",
      label: "Pimiento",
    },
    {
      value: "lettuce",
      label: "Lechuga",
    },
    {
      value: "parsley",
      label: "Perejil",
    },
    {
      value: "cauliflower",
      label: "Coliflor",
    },
    {
      value: "broccoli",
      label: "Brócoli",
    },
    {
      value: "spinach",
      label: "Espinacas",
    },
    {
      value: "green-beans",
      label: "Judías verdes",
    },
    {
      value: "kidney-bean",
      label: "Frijol",
    },
    {
      value: "peas",
      label: "Guisantes",
    },
    {
      value: "canola",
      label: "Canola",
    },
    {
      value: "onion",
      label: "Cebolla",
    },
    {
      value: "garlic",
      label: "Ajo",
    },
    {
      value: "melon",
      label: "Melón",
    },
    {
      value: "watermelon",
      label: "Sandía",
    },
    {
      value: "cherry",
      label: "Cereza",
    },
    {
      value: "sour-cherry",
      label: "Cereza agria",
    },
    {
      value: "strawberry",
      label: "Fresa",
    },
    {
      value: "kiwi",
      label: "Kiwi",
    },
    {
      value: "zucchini",
      label: "Calabacín",
    },
    {
      value: "pumpkin",
      label: "Calabaza",
    },
    {
      value: "pineapple",
      label: "Piña",
    },
    {
      value: "orange",
      label: "Naranja",
    },
    {
      value: "mandarin",
      label: "Mandarina",
    },
    {
      value: "grapefruit",
      label: "Pomelo",
    },
    {
      value: "bitter-orange",
      label: "Naranja amarga",
    },
    {
      value: "fig",
      label: "Higo",
    },
    {
      value: "pomegranate",
      label: "Granada",
    },
    {
      value: "quince",
      label: "Membrillo",
    },
    {
      value: "apple",
      label: "Manzana",
    },
    {
      value: "pear",
      label: "Pera",
    },
    {
      value: "peach",
      label: "Melocotón",
    },
    {
      value: "grape",
      label: "Uva",
    },
    {
      value: "apricot",
      label: "Albaricoque",
    },
    {
      value: "plum",
      label: "Ciruela",
    },
    {
      value: "lemon",
      label: "Limón",
    },
    {
      value: "lime",
      label: "Lima",
    },
    {
      value: "peanut",
      label: "Cacahuete",
    },
    {
      value: "walnut",
      label: "Nogal",
    },
    {
      value: "wheat",
      label: "Trigo",
    },
    {
      value: "barley",
      label: "Cebada",
    },
    {
      value: "oat",
      label: "Avena",
    },
    {
      value: "paddy",
      label: "Arroz",
    },
    { value: "corn", label: "Maíz" },
    {
      value: "millet",
      label: "Mijo",
    },
    {
      value: "rye",
      label: "Centeno",
    },
  ],
  ru: [
    { value: "tomato", label: "Томат" },
    { value: "potato", label: "Картофель" },
    { value: "eggplant", label: "Баклажан" },
    { value: "cabbage", label: "Капуста" },
    { value: "cucumber", label: "Огурец" },
    { value: "carrot", label: "Морковь" },
    { value: "pepper", label: "Перец" },
    { value: "lettuce", label: "Лук" },
    { value: "parsley", label: "Перец" },
    { value: "cauliflower", label: "Цветная капуста" },
    { value: "broccoli", label: "Брокколи" },
    { value: "spinach", label: "Спайси" },
    { value: "green-beans", label: "Зеленые бобы" },
    { value: "kidney-bean", label: "Фасоль" },
    { value: "peas", label: "Горох" },
    { value: "canola", label: "Канала" },
    { value: "onion", label: "Лук" },
    { value: "garlic", label: "Чеснок" },
    { value: "melon", label: "Мелочник" },
    { value: "watermelon", label: "Арбуз" },
    { value: "cherry", label: "Вишня" },
    { value: "sour-cherry", label: "Вишня" },
    { value: "strawberry", label: "Клубника" },
    { value: "kiwi", label: "Киви" },
    { value: "zucchini", label: "Кабачок" },
    { value: "pumpkin", label: "Тыква" },
    { value: "pineapple", label: "Ананас" },
    { value: "orange", label: "Апельсин" },
    { value: "mandarin", label: "Мандарин" },
    { value: "grapefruit", label: "Грейпфрут" },
    { value: "fig", label: "Инжир" },
    { value: "pomegranate", label: "Гранат" },
    { value: "quince", label: "Айва" },
    { value: "apple", label: "Яблоко" },
    { value: "pear", label: "Груша" },
    { value: "peach", label: "Персик" },
    { value: "grape", label: "Виноград" },
    { value: "apricot", label: "Абрикос" },
    { value: "plum", label: "Слива" },
    { value: "lemon", label: "Лимон" },
    { value: "lime", label: "Лайм" },
    { value: "peanut", label: "Орех" },
    { value: "walnut", label: "Грецкий орех" },
    { value: "wheat", label: "Пшеница" },
    { value: "barley", label: "Ячмень" },
    { value: "oat", label: "Овсяный" },
    { value: "paddy", label: "Пэдди" },
    { value: "corn", label: "Кукуруза" },
    { value: "millet", label: "Просо" },
    { value: "rye", label: "Рожь" },
  ],
};
